import * as logger from "@/tools/logger.js";
import * as statusMapper from "@/service/error_request_mapper.js";



const _FILE_PATH = "service/sfr/sub_activity_service.js";
const _M_GET_ALL = "getAllSubActivities";
const _M_GET_BY_ID = "getSubActivityById";
const _M_CREATE = "createSubActivity";
const _M_UPDATE = "updateSubActivity";
const _M_DELETE = "deleteSubActivity";

/** 
 * Service d'accès à l'API sous-activité
*/
export default class SubActivitiesService {
  constructor(apis) {
		this.apis = apis;
		this.api = this.apis.getSubActivitiesApi();
  }

	/////////////////////////////// GET /////////////////////////////////////////

	/** 
	 * Retourne la liste des sous-activitées 
	 * tri par ordre alphabétique
	*/
	async getAllSubActivities() {
		logger.debugFull(
			_FILE_PATH,
			_M_GET_ALL,
			"Récupération de la liste des sous activités.");

		return this.api.getAll()
			.then((data) => {
				data.sort(function(a, b) {
					return a.digitalName.localeCompare(b.digitalName);
				});
				return data;
			})
			.catch((error) => {
				let converter = new statusMapper.StatusConverter();
				converter.convert(error);
			});
	}

		/**
	 * Récupère un sous-activité par son id 
	 */
	async getSubActivityById(id) {
		logger.debugFull(_FILE_PATH,
			_M_GET_BY_ID,
			"Récupération d'une sous-activité avec l'id : " + id
		);

		return this.api.getById(id)
			.catch((error) => {
				let converter = new statusMapper.StatusConverter();
				converter.convert(error);
			});
	}
	


	/////////////////////////////// CREATE /////////////////////////////////////////

	/** 
	 * Création d'une sous-activité 
	*/
	async create(subActivity) {
		logger.debugFull(
			_FILE_PATH,
			_M_CREATE,
			"Création de la sous-activité : ",
			subActivity
		);

		return this.api.create(subActivity)
			.catch((error) => {
				let converter = new statusMapper.StatusConverter();
				converter.add404();
				converter.convert(error);
			});
	}

	/////////////////////////////// UPDATE /////////////////////////////////////////

	/** 
	 * Mise à jour des données la sous-activité 
	*/
	async update(subActivity) {
		logger.debugFull(_FILE_PATH, _M_UPDATE, "Mise à jour des infos de la sous-activité : " + subActivity.digitalName);

		return this.api.update(subActivity)
			.catch((error) => {
				let converter = new statusMapper.StatusConverter();
				converter.add404(subActivity.id);
				converter.convert(error);
			});
	}

	/////////////////////////////// DELETE /////////////////////////////////////////

	/** 
	 * Suppression de la sous-activité 
	*/
	async delete(idSubActivity) {
		logger.debugFull(_FILE_PATH, _M_DELETE, "Suppression de la sous-activité : " + idSubActivity);

		return this.api.delete(idSubActivity)
			.catch((error) => {
				let converter = new statusMapper.StatusConverter();
				converter.add409Integrity(idSubActivity);
				converter.convert(error);
			});
	}

} // END OF CLASS
